.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .button {
    font-size: 16px;
    line-height: 1.5;
  }
}
.button:hover {
  background-size: 100% 100%;
}
.area--one {
  width: calc(100% + 28px);
  margin-left: -14px;
}
.area--one .unit {
  width: calc(100% - 28px);
  margin-left: 14px;
  margin-right: 14px;
}
.area--one .unitOne--1-2 {
  width: calc(50% - 28px);
}
.area--one .unitOne--1-3 {
  width: calc((100% / 3) - 28px);
}
.area--one .unitTwo {
  max-width: 820px;
  margin-right: calc(100% - (14px + 820px));
}
.area--one .unitThree--imageAlignRight .unit__background {
  float: right;
  margin-left: 28px;
}
.area--one .unitThree--imageAlignRight .unit__body {
  float: right;
}
.area--one .unitThree--imageAlignLeft .unit__content {
  float: right;
  margin-left: 28px;
}
.area--one .unitThree--imageWidth1-2 .unit__background,
.area--one .unitThree--imageWidth1-2 .unit__content {
  width: calc(50% - 14px);
}
.area--one .unitThree--imageWidth1-2 .unit__body {
  max-width: 542px;
}
.area--one .unitThree--imageWidth2-3 .unit__background {
  width: calc(60% - 14px);
}
.area--one .unitThree--imageWidth2-3 .unit__content {
  width: calc(40% - 14px);
}
.area--one .unitFour {
  width: calc(50% - 28px);
}
.area--one .unit--fold div.more .part {
  margin-left: 14px;
  margin-right: 14px;
  width: calc(100% - 28px);
  max-width: 1080px;
}
.area--one .unit--fold div.more .part--1-2 {
  width: calc(50% - 28px);
}
.area--one .unit--fold div.more .part--1-4 {
  width: calc(50% - 28px);
}
.area--one .unit--form {
  width: calc(50% - 28px);
}
.unit--miniGallery .unit__body,
.unit--maxiGallery .unit__body {
  width: calc(100% + 28px);
  margin: -14px;
}
.unit--miniGallery .part,
.unit--maxiGallery .part {
  margin: 14px;
}
.unit--miniGallery .unit__body {
  width: calc(100% + 28px);
  margin: -14px;
}
.unit--miniGallery .part {
  width: calc(50% - 28px);
}
.unit--miniGallery .part:first-child {
  width: calc(100% - 28px);
}
.unit--maxiGallery .part {
  width: calc(100% - 28px);
}
.unit--maxiGallery .part--1-2 {
  width: calc(50% - 28px);
}
/*# sourceMappingURL=./screen-medium.css.map */